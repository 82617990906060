
//674357219370735 fb app id real
//2049822855318927 test app id fb
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import axios from "axios";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import store from "@/store";
import i18n from "@/core/plugins/i18n";
import { func } from "@/core/plugins/globalfunc";
import * as Yup from "yup";

declare const window: any;
var facebookConnectPlugin;
export default defineComponent({
  name: "customers-listing",
  mixins: [func],
  components: {
    //Datatable,
  },
  setup() {
    // Define a validation schema
    const schema = Yup.object({
      username: Yup.string().required("inputUsername"),
      password: Yup.string().required("inputPassword"),
    });

    // Create a form context with the validation schema
    const { errors, handleSubmit, validate } = useForm({
      validationSchema: schema,
    });

    // No need to define rules for fields
    //const username = ref('');
    const { value: username, errorMessage: usernameError } =
      useField<string>("username");
    const { value: password, errorMessage: passwordError } =
      useField<string>("password");

    return {
      //login,
      validate,
      errors,
      username,
      usernameError,
      password,
      passwordError,
    };
  },
  data() {
    return {};
  },
  computed: {
    tokenValue() {
      return store.state.token;
    },
    uidValue() {
      return store.state.uid;
    },
    usernameValue() {
      return store.state.username;
    },
    fbidValue() {
      return store.state.fbid;
    },
    prevRouteValue() {
      if (
        store.state.prevRoute.name == "login" ||
        store.state.prevRoute.name == "logout" ||
        store.state.prevRoute.name == "register" ||
        store.state.prevRoute.name == "forgot" ||
        store.state.prevRoute.name == "reset"
      ) {
        return "";
      } else {
        return store.state.prevRoute.fullPath;
      }
    },
  },
  mounted() {
    if (store.state.uid) {
      ElMessage.success(this.$t("alreadyLogin"));
      this.$router.push({ name: 'tourSeries' })
    }
  },
  methods: {
    fbmobilelogin() {
      window.facebookConnectPlugin.login(
        ["public_profile"],
        (response) => {
          if (response.authResponse) {
            //Get FacebookID with accessToken from backend
            axios
              .post(
                "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=fblogin&action=fblogin",
                JSON.stringify({
                  accessToken: response.authResponse.accessToken,
                })
              )
              .then((response) => {
                if (response.data === "fail" || !response.data) {
                  //Show error
                  ElMessage.error(this.$t("loginFail"));
                } else if (response.data === "register") {
                  //Go to register page when Database have no FacebookID
                  this.$router.replace({ name: "registerFB" });
                } else if (response.data.username) {
                  //Log in frontend if there is FacebookID in Database
                  store.dispatch("setUsername", response.data.username);
                  store.dispatch("setUid", response.data.uid);
                  store.dispatch("setToken", response.data.jwt);
                  axios.defaults.headers.common["Authorization"] =
                    "Bearer " + response.data.jwt; // Save returned JWT token to header
                  ElMessage({
                    message: this.$t("loginSuccess"),
                    type: "success",
                  });
                  if (this.$route.fullPath == this.prevRouteValue || !this.prevRouteValue) {
                    //Go to previous page or Homepage after login
                    this.$router.push({ name: 'tourSeries' })
                  } else {
                    this.$router.push(this.prevRouteValue);
                  }
                } else {
                  ElMessage.error(this.$t("loginFail"));
                }
              });
          }
        },
        (error) => {
          ElMessage.error(this.$t("loginFail"));
        }
      );
    },
    fblogin() {
      if (store.state.uid) {
        //If there's UID, show message already login
        ElMessage.success(this.$t("alreadyLogin"));
      } else {
        //Try Facebook Login
        window.FB.login((response) => {
          if (response.authResponse) {
            //Get FacebookID with accessToken from backend
            axios
              .post(
                "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=fblogin&action=fblogin",
                JSON.stringify({
                  accessToken: response.authResponse.accessToken,
                })
              )
              .then((response) => {
                if (response.data === "fail" || !response.data) {
                  //Show error
                  ElMessage.error(this.$t("loginFail"));
                } else if (response.data === "register") {
                  //Go to register page when Database have no FacebookID
                  this.$router.replace({ name: "registerFB" });
                } else if (response.data.username) {
                  //Log in frontend if there is FacebookID in Database
                  store.dispatch("setUsername", response.data.username);
                  store.dispatch("setUid", response.data.uid);
                  store.dispatch("setToken", response.data.jwt);
                  axios.defaults.headers.common["Authorization"] =
                    "Bearer " + response.data.jwt; // Save returned JWT token to header
                  ElMessage({
                    message: this.$t("loginSuccess"),
                    type: "success",
                  });
                  if (this.$route.fullPath == this.prevRouteValue) {
                    //Go to previous page or Homepage after login
                    this.$router.push("/");
                  } else {
                    this.$router.push(this.prevRouteValue);
                  }
                } else {
                  ElMessage.error(this.$t("loginFail"));
                }
              });
          } else if (response) {
            //ElMessage.error(this.$t('loginFail'))
          } else {
            ElMessage.error(this.$t("loginFail"));
          }
        });
      }
    },
    fblogout() {
      window.FB.logout(function (response) {
        store.dispatch("setFbid", null);
        // handle the response
      });
    },
    login() {
      if (store.state.uid) {
        //Show already login when there's UID
        ElMessage.success(this.$t("alreadyLogin"));
      } else {
        this.validate().then((values) => {
          if (values.valid) {
            //Log in backend
            axios
              .post(
                "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=login",
                JSON.stringify({
                  username: encodeURI(this.username),
                  password: this.password,
                })
              )
              .then((response) => {
                if (response.data === "fail" || !response.data) {
                  //Show login fail
                  ElMessage.error(this.$t("loginFail"));
                } else {
                  store.dispatch("setUsername", response.data.username);
                  store.dispatch("setUid", response.data.uid);
                  store.dispatch("setToken", response.data.jwt);
                  axios.defaults.headers.common["Authorization"] =
                    "Bearer " + response.data.jwt; // Save returned JWT token to header
                  ElMessage({
                    message: this.$t("loginSuccess"),
                    type: "success",
                  });
                  if (this.$route.fullPath == this.prevRouteValue) {
                    this.$router.push("/");
                  } else {
                    this.$router.push(this.prevRouteValue);
                  }
                }
              })
              .catch((e) => {
                ElMessage.error(this.$t("loginFail"));
              });
          }
        });
      }
    },
    showToken() {
      ElMessage({
        message: localStorage.getItem("JWT_TOKEN")?.toString(),
        type: "success",
      });
    },
    resetToken() {
      localStorage.removeItem("JWT_TOKEN");
      localStorage.removeItem("uid");
      localStorage.removeItem("username");
      this.$router.push({ name: "tour" });
    },
    logout() {
      this.logoutClear();
    },
  },
});
